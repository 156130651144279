<script lang="ts" setup>
import { onMounted } from 'vue';
import { handleApiRequest } from 'src/api/handleApiRequest';
import { getPatientProfileData, getCommunicationPreferences } from 'src/api/endpoints/userWeb';
import { useDashboardStore } from 'src/store/dashboardModule';
import { useQuasar } from 'quasar';
import { useRouter } from 'vue-router';
import { resetProcedureRelatedState } from '@/services/userService';
import { loadPatientInfoAndTasks } from 'src/pages/TheDataHydrator';
import { useMessagesStore, useSessionStore } from 'src/store';
import { EngagementSurveyTypeEnum } from '@/constants/survey';

const dashboardStore = useDashboardStore();
const $q = useQuasar()
const router = useRouter()

const sessionStore = useSessionStore();
const messagesStore = useMessagesStore();

const props = defineProps({
  reload: {
    type: String,
    default: 'true'
  }
})

onMounted(async () => {
  if (props.reload === 'true') {
    // When procedure is changed we reset procedure data
    resetProcedureRelatedState()
  } else {
    // Reload Interrupts for later usages
    const patientResponse = await handleApiRequest(() => getPatientProfileData(true), null, $q);
    
    if (patientResponse) {
      dashboardStore.loadPatient(patientResponse.result);
    }
  }

  $q.loading.show()

  try {

    await loadPatientInfoAndTasks(sessionStore, messagesStore, dashboardStore, $q);

    $q.loading.hide()

    var hasInterruptRedirect = dashboardStore.patient?.interruptData.interrupts.some(async (interrupt) => {
      // About Your Data interrupt(s).
      if (interrupt.type === 'careteam-data-share-consent') {
        router.replace({ name: 'AboutYourData', params: { reload: props.reload } });
        return true;
      }

      // Pain & Opioid interrupt(s).
      else if (interrupt.type === 'Pain_and_Opioid_Pre_Op' || interrupt.type === 'Pain_and_Opioid_Post_Op') {
        router.replace({ name: 'PainRating', params: { reload: props.reload } });
        return true;
      }

      // Day 42 interrupt(s).
      else if (interrupt.type === EngagementSurveyTypeEnum.day42 || interrupt.type === EngagementSurveyTypeEnum.day42NoPredictions) {
        router.replace({ name: 'Survey', params: { surveyId: interrupt.surveyId, tickSignature: null } });
        return true;
      }

      // Communication Preferences interrupt(s).
      else if (interrupt.type === 'Communication_Preferences') {
        const communicationPreferencesResponse = await handleApiRequest(() => getCommunicationPreferences(), null, $q);

        if (communicationPreferencesResponse) {
          router.replace({ name: 'CommunicationPreferences', params: { userResearch: communicationPreferencesResponse.userResearchEnabled, email: communicationPreferencesResponse.emailEnabled, phone: communicationPreferencesResponse.phoneEnabled } });
          return true;
        } 

        return false;
      }
    });

    if (!hasInterruptRedirect) {
      router.replace({ name: 'Dashboard.Home', params: { reload: props.reload } });
    }
  } catch (error) {
    $q.loading.hide()
  }
})
</script>

<template>
  <div></div>
</template>