<script lang="ts" setup>
import {
  useDashboardStore,
  useSessionStore
} from 'src/store';
import {useQuasar} from 'quasar';
import {useRouter} from 'vue-router';
import {i18n} from '@/plugins/i18n';
import {
  computed,
  type ComputedRef,
  onActivated,
  ref,
  watch
} from 'vue';
import {
  type DataSourceResponse,
  type DropdownMenuItem,
  type PatientProgressResponse,
  type SubNavItem
} from '@/types/webContracts';
import {
  AIProgressDataTypes,
  AIProgressRanks
} from '@/constants/enums';
import {handleApiRequest} from '@/api/handleApiRequest';
import {type Svg} from '@/types/assets';
import {
  hkWalkSessions,
  piqQualifiedSteps,
  piqWalkMotion
} from '@/assets';
import {getPatientDataSources, getPatientProgressComparison} from '@/api/endpoints/statisticsWeb';
import MyRcvyProgNoData from '@/pages/DashboardMyStats/components/MyRcvyProgNoData.vue';
import DropdownMenu from '@/components/DropdownMenu.vue';
import SubNavigationLayout from '@/components/layouts/SubNavigation/SubNavigationLayout.vue';
import MyRcvyProgComparison from '@/pages/DashboardMyStats/components/MyRcvyProgComparison.vue';

const dashboardStore = useDashboardStore(),
  sessionStore = useSessionStore();

const $q = useQuasar(),
  router = useRouter(),
  $t = i18n.global.t;

const props = defineProps({
  deviceId: {
    type: String
  },
  metric: {
    type: String
  }
});

const changingSource = ref(false),
  metricData = ref<PatientProgressResponse | undefined>(undefined),
  noData = ref(false),
  selectedMetric = ref(''),
  selectedSource = ref<DataSourceResponse | undefined>(undefined),
  sources = ref<DataSourceResponse[]>([]);

const isLoading = computed(() => !dashboardStore.patient),
  sourcesLength = computed(() => sources.value.length),
  greaterThan1 = computed(() => sourcesLength.value > 1);

const dropdownMenuOpts = computed(() => {
  let options: DropdownMenuItem[] = [];
  
  if (greaterThan1.value) {
    sources.value.forEach(source => {
      const thisDeviceId = source.eocDeviceId ? source.eocDeviceId.toString() : source.sourceType,
        thisMetric = source.comparisonMetrics.length > 0 ? source.comparisonMetrics[0].comparisonDataType : undefined;
      
      let thisParams = {};
      
      if (thisDeviceId) thisParams = { deviceId: thisDeviceId, ...thisParams };
      if (thisMetric) thisParams = { metric: thisMetric, ...thisParams };
      
      options.push({
        active: selectedSource.value?.sourceType === source.sourceType && selectedSource.value?.eocDeviceId === source.eocDeviceId,
        label: source.localizedName,
        name: 'Dashboard.MyStats.MyProgress',
        params: thisParams,
        value: source.eocDeviceId
      });
    });
  }
  
  return options;
});

const subNavItems: ComputedRef<SubNavItem[]> = computed(() => {
  let items: SubNavItem[] = [];
  
  selectedSource.value?.comparisonMetrics.forEach(compMetric => {
    items.push({
      customIconClass: subNavItemCusIconClass(compMetric.rank),
      icon: subNavItemIcon(compMetric.comparisonDataType),
      isCustomLabel: true,
      label: subNavItemLabel(compMetric.comparisonDataType),
      name: 'Dashboard.MyStats.MyProgress',
      params: { deviceId: selectedSource.value?.eocDeviceId, metric: compMetric.comparisonDataType },
      value: compMetric.comparisonDataType
    });
  });
  
  return items;
});

function clearMetric() {
  metricData.value = undefined;
  selectedMetric.value = '';
}

function clearSource() {
  clearMetric();
  
  noData.value = false;
  selectedSource.value = undefined;
}

function determineMetric() {
  
  // Determine metric if `selectedSource` has `comparisonMetrics`.
  if (!noData.value) {
    
    // Determine metric
    const potentialMetric = props.metric

      // Find the selected metric associated with the Source.
      ? selectedSource.value?.comparisonMetrics.find(m => m.comparisonDataType === props.metric)?.comparisonDataType

      // Find a metric associated with the Source.
      : selectedSource.value?.comparisonMetrics[0].comparisonDataType;
    
    // Set the metric.
    if (potentialMetric) {
      selectedMetric.value = potentialMetric;
      
      // If we did not receive `props.deviceId` or `props.metric`, then re-route to the URL that would provide those
      // URL based props.
      if (!props.deviceId || !props.metric) {
        
        router.replace({
          name: 'Dashboard.MyStats.MyProgress',
          params: { deviceId: selectedSource.value?.eocDeviceId, metric: selectedMetric.value }
        });
      }

      hydrateMetricData();
    }
  }
}

function determineSource() {

  // Determine source.
  const potentialSrc = props.deviceId

    // Find the selected source associate with the EOC Device Id.
    ? findSelectedSource(props.deviceId)

    // Find the source associate with the current EOC with the most metrics.
    : sources.value.filter(s => s.eocId === sessionStore.user?.selectedEocId)
      .sort((a, b) => a.comparisonMetrics.length > b.comparisonMetrics.length ? 1 : -1)[0];

  // Set the source.
  if (potentialSrc) {
    selectedSource.value = potentialSrc;

    // Determine if source does not have data.
    if (selectedSource.value?.comparisonMetrics.length === 0) noData.value = true;
  } else {

    // Go back to where you came if `potentialSrc` is undefined.
    goBack();
  }
}

function goBack() {
  router.go(-1);
  
  $q.notify({
    type: 'error',
    message: $t('gen_error_system_issue')
  })
}

function findSelectedSource(value: string) {
  let potentialSrc: DataSourceResponse | undefined;
  
  if (value.toString().toLowerCase() === 'wearable' || value.toString().toLowerCase() === 'rom') {
    potentialSrc = sources.value.find(s => s.sourceType.toLowerCase() === value.toLowerCase());
  } else {
    potentialSrc = sources.value.find(s => s.eocDeviceId === Number.parseInt(value, 10));
  }
  
  return potentialSrc;
}

async function hydrateMetricData() {
  if (selectedSource.value !== undefined && selectedSource.value.eocId !== null) {
    const getPatProgComparison = await handleApiRequest(() => getPatientProgressComparison(selectedSource.value.eocId, selectedMetric.value, selectedSource.value?.eocDeviceId), null, $q);
    
    if (getPatProgComparison?.result) {
      metricData.value = getPatProgComparison.result;
      
      return;
    } else {
      noData.value = true;
      
      return;
    }
  }

  // Go back to where you came if `selectedSource` is `undefined` and `selectedSource.value.eocId` is `null`.
  goBack();
}

function subNavItemCusIconClass(value: string) {
  let iconClass: string;

  switch (value) {
    case AIProgressRanks.Low:
      iconClass = 'bg-secondary';
      break;
    case AIProgressRanks.OnTrack:
      iconClass = 'bg-primary';
      break;
    case AIProgressRanks.High:
      iconClass = 'bg-negative';
      break;
    default:
      iconClass = '';
      break;
  }

  return iconClass;
}

function subNavItemIcon(value: string) {
  let icon: string | Svg;

  switch (value) {
    case AIProgressDataTypes.StepsAvg:
      icon = 'fas fa-shoe-prints fa-rotate-270';
      break;
    case AIProgressDataTypes.MaxStepsAvg:
      icon = piqQualifiedSteps;
      break;
    case AIProgressDataTypes.KneeROMAvg:
      icon = piqWalkMotion;
      break;
    case AIProgressDataTypes.WalkSessionCountAvg:
      icon = hkWalkSessions;
      break;
    default:
      icon = '';
      break;
  }

  return icon;
}

function subNavItemLabel(value: string) {
  let label: string;
  
  switch (value) {
    case AIProgressDataTypes.StepsAvg:
      label = $t('steps_metric');
      break;
    case AIProgressDataTypes.MaxStepsAvg:
      label = $t('qualified_steps_piq');
      break;
    case AIProgressDataTypes.KneeROMAvg:
      label = $t('walking_rom_piq');
      break;
    case AIProgressDataTypes.WalkSessionCountAvg:
      label = $t('walking_sessions_steps');
      break;
    default:
      label = '';
      break;
  }

  return label;
}

onActivated(async () => {
  const patDataSources = await handleApiRequest(() => getPatientDataSources(), null, $q);
  
  if (patDataSources?.result && patDataSources?.result.dataSources) {
    sources.value = patDataSources.result.dataSources.filter(s => s.sourceType === 'PersonaIQ');
    
    // Determine the/a source.
    determineSource();

    // Determine the/a metric.
    determineMetric();
  } else {

    // Go back to where you came if `patDataSources` does not have a `result` and `dataSources`.
    goBack();
  }
});

watch(() => props.deviceId, (newDeviceId, oldDeviceId) => {
  if (newDeviceId !== oldDeviceId) {
    
    // Show "loading" spinner to signify we are doing stuff.
    $q.loading.show();
    
    // Changing source, so don't run metric change.
    changingSource.value = true;
    
    // Clear the source.
    clearSource();
    
    // Determine the/a source.
    determineSource();

    // Determine the/a metric.
    determineMetric();
    
    // If there is no metric change...
    if (selectedMetric.value === props.metric) {
      
      // Reset ref for next change.
      changingSource.value = false;

      // Hide "loading" spinner since we are done doing stuff.
      $q.loading.hide();
    }
  }
});

watch(() => props.metric, (newMetric, oldMetric) => {
  if (newMetric !== oldMetric && !changingSource.value) {

    // Show "loading" spinner to signify we are doing stuff.
    $q.loading.show();
    
    // Clear the metric.
    clearMetric();

    // Determine the/a metric.
    determineMetric();
  }

  // Reset ref for next change.
  changingSource.value = false;

  // Hide "loading" spinner since we are done doing stuff.
  $q.loading.hide();
});
</script>

<template>
  <zbm-async-content :loading="isLoading">
    
    <!-- Skeleton(s) -->
    <template #loading>
      
      <!-- Title -->
      <q-skeleton class="mb-24" height="34px" width="100%" />

      <!-- Tab Navigation -->
      <q-skeleton class="mb-30" height="34px" width="100%" />
      
      <!-- Description -->
      <q-skeleton class="mb-30" height="72px" width="100%" />

      <!-- Comparison -->
      <q-skeleton class="mb-24" height="382px" width="100%" />

      <!-- Explanation -->
      <q-skeleton height="172px" width="100%" />
    </template>
  
    <!-- No Data -->
    <template v-if="noData">
      <MyRcvyProgNoData />
    </template>
    
    <!-- Has Data -->
    <template v-else>
      
      <!-- Sources Navigation -->
      <template v-if="greaterThan1">
        <div class="row mb-10">
          <div class="col">
            <h1>{{ $t('piq_bilateral_source') }}</h1>
          </div>
        </div>
      
        <div class="row mb-20">
          <div class="col-auto">
            <DropdownMenu :options="dropdownMenuOpts" />
          </div>
        </div>
      </template>
      
      <!-- Content -->
      <SubNavigationLayout v-model="selectedMetric"
                           :inline-label="true" 
                           :nav-items="subNavItems"
                           :title="greaterThan1 ? undefined : 'my_progress_title'">
        
        <!-- Navigation Content -->
        <template #content>
          <MyRcvyProgComparison :is-loading="isLoading"
                                :metric="selectedMetric"
                                :metric-data="metricData" />
        </template>
      </SubNavigationLayout>
    </template>
  </zbm-async-content>
</template>

<style lang="scss" scoped>

// Tabs Custom Label Icon
:deep(.q-tab__icon--unique) {
  display: flex;
  width: 16px;
  height: 16px;
  background-color: $primary;
  color: $white;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  
  .q-icon {
    font-size: 8px;
  }
  
  svg {
    width: 10px;
    height: auto;
  }
}
</style>